import {
  DAMenu,
  DAPMenu,
  DEMenu,
  DFAAMMenu,
  DFDMMenu,
  DICSMenu,
  DIRPMenu,
  DRDMenu,
  DSDCMenu,
  DWECMenu,
  SDICMenu,
  NSSMenu,
  SCSTMenu,
  SPORTSMenu,
  ARCMenu,
  SRMenu,
} from "../components/Layout/DepartmentMenu";
import { JNTUK_API_Urls } from "./api.config";
const currentDate = new Date();
const formattedDate = currentDate.toISOString();
export const getPageTitle = (currentDomain) => {
  if (currentDomain === "dap") return "Academic & Planning";
  else if (currentDomain === "da") return "Admissions";
  else if (currentDomain === "de") return "Evaluation";
  else if (currentDomain === "drd") return "Research & Development";
  else if (currentDomain === "dirp") return "Industrial Relations & Placements";
  else if (currentDomain === "dics") return "Industrial Consultancy Services";
  else if (currentDomain === "dfaam") return "Foreign Affairs & Alumni Matters";
  else if (currentDomain === "dfd") return "Foreign Affairs & Alumni Matters";
  else if (currentDomain === "sdc") return "Software Development Center";
  else if (currentDomain === "wec") return "Women Empowerment Cell";
  else if (currentDomain === "sdic")
    return "Future Skill Development Centre & Incubation Centre";
  else if (currentDomain === "nss") return "National Service Scheme Cell";
  else if (currentDomain === "scst") return "SC / ST CELL";
  else if (currentDomain === "sports") return "Sports Council";
  else if (currentDomain === "arc") return "Anti Ragging Cell";
  else return "";
};
export const getAPIPath = (currentDomain) => {
  if (currentDomain === "dap")
    return {
      home: JNTUK_API_Urls["DAP-Home"],
      profile: JNTUK_API_Urls["JNTUK_DAP_Department"],
      profileLink: "/profile/academics-and-planning-dap",
      contact: JNTUK_API_Urls["DAP-Contact-Us"],
      about_directorate: JNTUK_API_Urls["DAP-Directorate"],
      newsletter: JNTUK_API_Urls["DAP-Newsletter"],
      downloads: JNTUK_API_Urls["DAP-Downloads"],
      Research_Programmes: JNTUK_API_Urls["DAP-Research-Programmes"],
      gallery: JNTUK_API_Urls["DAP-Gallery"],
      home_slider: `dap-home-page-banners?populate=*&filters[End_Time][$gte]=${formattedDate}`,
    };
  else if (currentDomain === "da")
    return {
      home: JNTUK_API_Urls["DA-Home"],
      profile: JNTUK_API_Urls["JNTUK_DA_Department"],
      profileLink: "/profile/admissions-da",
      contact: JNTUK_API_Urls["DA-Contact-Us"],
      about_directorate: JNTUK_API_Urls["DA-Directorate"],
      newsletter: JNTUK_API_Urls["DA-Newsletter"],
      downloads: JNTUK_API_Urls["DA-Downloads"],
      others: JNTUK_API_Urls["DA-Others"],
      gallery: JNTUK_API_Urls["DA-Gallery"],
      home_slider: `da-home-page-banners?populate=*&filters[End_Time][$gte]=${formattedDate}`,
    };
  else if (currentDomain === "de")
    return {
      home: JNTUK_API_Urls["DE-Home"],
      profile: JNTUK_API_Urls["JNTUK_DE_Department"],
      profileLink: "/profile/director-of-evaluation-de",
      contact: JNTUK_API_Urls["DE-Contact-Us"],
      about_directorate: JNTUK_API_Urls["DE-Directorate"],
      newsletter: JNTUK_API_Urls["DE-Newsletter"],
      downloads: JNTUK_API_Urls["DE-Downloads"],
      others: JNTUK_API_Urls["DE-Others"],
      gallery: JNTUK_API_Urls["DE-Gallery"],
      home_slider: `de-home-page-banners?populate=*&filters[End_Time][$gte]=${formattedDate}`,
    };
  else if (currentDomain === "drd")
    return {
      home: JNTUK_API_Urls["DRD-Home"],
      profile: JNTUK_API_Urls["JNTUK_DRD_Department"],
      profileLink: "/profile/director-research-and-development",
      contact: JNTUK_API_Urls["DRD-Contact-Us"],
      about_directorate: JNTUK_API_Urls["DRD-Directorate"],
      newsletter: JNTUK_API_Urls["DRD-Newsletter"],
      downloads: JNTUK_API_Urls["DRD-Downloads"],
      others: JNTUK_API_Urls["DRD-Others"],
      gallery: JNTUK_API_Urls["DRD-Gallery"],
      home_slider: `drd-home-page-banners?populate=*&filters[End_Time][$gte]=${formattedDate}`,
    };
  else if (currentDomain === "dirp")
    return {
      home: JNTUK_API_Urls["DIRP-Home"],
      profile: JNTUK_API_Urls["DIRP_Department"],
      profileLink: "/profile/director-of-industrial-relations-placements",
      contact: JNTUK_API_Urls["DIRP-Contact-Us"],
      about_directorate: JNTUK_API_Urls["DIRP-Directorate"],
      newsletter: JNTUK_API_Urls["DIRP-Newsletter"],
      downloads: JNTUK_API_Urls["DIRP-Downloads"],
      others: JNTUK_API_Urls["DIRP-Others"],
      gallery: JNTUK_API_Urls["DIRP-Gallery"],
      home_slider: `dirp-home-page-banners?populate=*&filters[End_Time][$gte]=${formattedDate}`,
    };
  else if (currentDomain === "dics")
    return {
      home: JNTUK_API_Urls["DICS-Home"],
      profile: JNTUK_API_Urls["DICS_Department"],
      profileLink: "/profile/director-of-industrial-consultancy-services",
      contact: JNTUK_API_Urls["DICS-Contact-Us"],
      about_directorate: JNTUK_API_Urls["DICS-Directorate"],
      newsletter: JNTUK_API_Urls["DICS-Newsletter"],
      downloads: JNTUK_API_Urls["DICS-Downloads"],
      others: JNTUK_API_Urls["DICS-Others"],
      gallery: JNTUK_API_Urls["DICS-Gallery"],
      home_slider: `dics-home-page-banners?populate=*&filters[End_Time][$gte]=${formattedDate}`,
    };
  else if (currentDomain === "dfaam") {
    return {
      home: JNTUK_API_Urls["DFAAM-Home"],
      profile: JNTUK_API_Urls["DFAAM_Department"],
      profileLink: "/profile/director-of-foreign-affairs-alumni-matters",
      contact: JNTUK_API_Urls["DFAAM-Contact-Us"],
      about_directorate: JNTUK_API_Urls["DFAAM-Directorate"],
      newsletter: JNTUK_API_Urls["DFAAM-Newsletter"],
      downloads: JNTUK_API_Urls["DFAAM-Downloads"],
      others: JNTUK_API_Urls["DFAAM-Others"],
      gallery: JNTUK_API_Urls["DFAAM-Gallery"],
      home_slider: `dfaam-home-page-banners?populate=*&filters[End_Time][$gte]=${formattedDate}`,
    };
  } else if (currentDomain === "diqac")
    return {
      home: JNTUK_API_Urls["DFD-Home"],
      profile: JNTUK_API_Urls["DIQAC_Department"],
      profileLink: "/profile/internal-quality-assurance-cell",
      contact: JNTUK_API_Urls["DFD-Contact-Us"],
      newsletter: JNTUK_API_Urls["DFD-Newsletter"],
      downloads: JNTUK_API_Urls["DFD-Downloads"],
      others: JNTUK_API_Urls["DFD-Others"],
      gallery: JNTUK_API_Urls["DFD-Gallery"],
      home_slider: `diqac-home-page-banners?populate=*&filters[End_Time][$gte]=${formattedDate}`,
    };
  else if (currentDomain === "sdc")
    return {
      home: JNTUK_API_Urls["DSDC-Home"],
      profile: JNTUK_API_Urls["DSDC_Department"],
      profileLink: "/profile/software-development-centre",
      contact: JNTUK_API_Urls["DSDC-Contact-Us"],
      about_directorate: JNTUK_API_Urls["DSDC-Directorate"],
      newsletter: JNTUK_API_Urls["DSDC-Newsletter"],
      gallery: JNTUK_API_Urls["DSDC-Gallery"],
      home_slider: `dsdc-home-page-banners?populate=*&filters[End_Time][$gte]=${formattedDate}`,
    };
  else if (currentDomain === "wec")
    return {
      home: JNTUK_API_Urls["DWEC-Home"],
      profile: JNTUK_API_Urls["DWEC_Department"],
      profileLink: "/profile/women-empowerment-cell",
      contact: JNTUK_API_Urls["DWEC-Contact-Us"],
      about_directorate: JNTUK_API_Urls["DWEC-Directorate"],
      newsletter: JNTUK_API_Urls["DWEC-Newsletter"],
      gallery: JNTUK_API_Urls["DWEC-Gallery"],
      home_slider: `dwec-home-page-banners?populate=*&filters[End_Time][$gte]=${formattedDate}`,
      compliant:JNTUK_API_Urls["DWEC-Compliant"],
      programmes:JNTUK_API_Urls["DWEC-Programmes"],
      notifications:JNTUK_API_Urls["DWEC-Notifications"]
    };
  else if (currentDomain === "sdic")
    return {
      home: JNTUK_API_Urls["SDIC-Home"],
      profile: JNTUK_API_Urls["SDIC_Department"],
      profileLink:
        "/profile/director-of-skill-development-centre-incubation-centre",
      contact: JNTUK_API_Urls["SDIC-Contact-Us"],
      about_us: JNTUK_API_Urls["SDIC-About-Us"],
      downloads: JNTUK_API_Urls["SDIC-Downloads"],
      others: JNTUK_API_Urls["SDIC-Others"],
      gallery: JNTUK_API_Urls["SDIC-Gallery"],
      home_slider: `sdic-home-page-banners?populate=*&filters[End_Time][$gte]=${formattedDate}`,
    };
  else if (currentDomain === "nss")
    return {
      home: JNTUK_API_Urls["NSS-Home"],
      profile: JNTUK_API_Urls["NSS_Department"],
      profileLink: "/profile/nss",
      contact: JNTUK_API_Urls["NSS-Contact-Us"],
      about_us: JNTUK_API_Urls["NSS-About-Us"],
      downloads: JNTUK_API_Urls["NSS-Downloads"],
      others: JNTUK_API_Urls["NSS-Others"],
      gallery: JNTUK_API_Urls["NSS-Gallery"],
      home_slider: `nss-home-page-banners?populate=*&filters[End_Time][$gte]=${formattedDate}`,
    };
  else if (currentDomain === "scst")
    return {
      home: JNTUK_API_Urls["SCST-Home"],
      profile: JNTUK_API_Urls["SCST_Department"],
      profileLink: "/profile/scst",
      contact: JNTUK_API_Urls["SCST-Contact-Us"],
      about_us: JNTUK_API_Urls["SCST-About-Us"],
      downloads: JNTUK_API_Urls["SCST-Downloads"],
      others: JNTUK_API_Urls["SCST-Others"],
      gallery: JNTUK_API_Urls["SCST-Gallery"],
      home_slider: `scst-home-page-banners?populate=*&filters[End_Time][$gte]=${formattedDate}`,
    };
  else if (currentDomain === "sports")
    return {
      home: JNTUK_API_Urls["SPORTS-Home"],
      profile: JNTUK_API_Urls["SPORTS_Department"],
      profileLink: "/profile/sports",
      contact: JNTUK_API_Urls["SPORTS-Contact-Us"],
      about_us: JNTUK_API_Urls["SPORTS-About-Us"],
      downloads: JNTUK_API_Urls["SPORTS-Downloads"],
      others: JNTUK_API_Urls["SPORTS-Others"],
      gallery: JNTUK_API_Urls["SPORTS-Gallery"],
      home_slider: `sports-home-page-banners?populate=*&filters[End_Time][$gte]=${formattedDate}`,
    };
  else if (currentDomain === "arc")
    return {
      home: JNTUK_API_Urls["ARC-Home"],
      profile: JNTUK_API_Urls["ARC_Department"],
      profileLink: "/profile/arc",
      contact: JNTUK_API_Urls["ARC-Contact-Us"],
      about_us: JNTUK_API_Urls["ARC-About-Us"],
      downloads: JNTUK_API_Urls["ARC-Downloads"],
      others: JNTUK_API_Urls["ARC-Others"],
      gallery: JNTUK_API_Urls["ARC-Gallery"],
      home_slider: `arc-home-page-banners?populate=*&filters[End_Time][$gte]=${formattedDate}`,
    };
    else if (currentDomain === "sr")
      return {
        home: JNTUK_API_Urls["SR-Home"],
        profile: JNTUK_API_Urls["SR_Department"],
        profileLink: "/profile/sponsored-research",
        contact: JNTUK_API_Urls["ARC-Contact-Us"],
        about_us: JNTUK_API_Urls["ARC-About-Us"],
        downloads: JNTUK_API_Urls["ARC-Downloads"],
        others: JNTUK_API_Urls["ARC-Others"],
        gallery: JNTUK_API_Urls["ARC-Gallery"],
        home_slider: `sr-home-page-banners?populate=*&filters[End_Time][$gte]=${formattedDate}`,
      };
  else return null;
};

export const getMenu = (currentDomain) => {
  if (currentDomain === "dap") return <DAPMenu />;
  else if (currentDomain === "da") return <DAMenu />;
  else if (currentDomain === "de") return <DEMenu />;
  else if (currentDomain === "drd") return <DRDMenu />;
  else if (currentDomain === "dirp") return <DIRPMenu />;
  else if (currentDomain === "dics") return <DICSMenu />;
  else if (currentDomain === "dfaam") return <DFAAMMenu />;
  else if (currentDomain === "diqac") return <DFDMMenu />;
  else if (currentDomain === "sdc") return <DSDCMenu />;
  else if (currentDomain === "wec") return <DWECMenu />;
  else if (currentDomain === "sdic") return <SDICMenu />;
  else if (currentDomain === "nss") return <NSSMenu />;
  else if (currentDomain === "scst") return <SCSTMenu />;
  else if (currentDomain === "sports") return <SPORTSMenu />;
  else if (currentDomain === "arc") return <ARCMenu />;
  else if (currentDomain === "sr") return <SRMenu />;
  else return null;
};
